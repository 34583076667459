<template>
    <el-container>

        <el-main>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">供片系统设备方</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/' }">内部管理</el-breadcrumb-item>
                <el-breadcrumb-item><a href="">人员权限</a></el-breadcrumb-item>
                <el-breadcrumb-item><a href="">菜单列表</a></el-breadcrumb-item>
            </el-breadcrumb>
            <h3>注意: [蓝色标签]标签 [绿色标签]页面 [红色标签]无效/未关联的标签/页面 [灰色标签]禁用页面</h3>
            <el-tree
                    :data="allMenus"
                    node-key="id"
                    default-expand-all
                    draggable
                    ref="menuTree"
                    :props="{label:'name'}"
                    :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node, data }">
              <el-tag size="small" :type="g_getMenuTagColor(data)">{{ data.name }} {{suffix(data)}}</el-tag>
            <span style="margin-left: 30px">
              <el-button
                      type="text"
                      size="mini"
                      @click="() => appendMenu(data)">
                <i class="el-icon-folder-add"></i>
              </el-button>
                <el-divider direction="vertical"></el-divider>
                        <el-button
                                type="text"
                                size="mini"
                                @click="() => editMenu(data)">
                <i class="el-icon-edit"></i>
              </el-button>
                <el-divider direction="vertical"></el-divider>
                       <template>
                            <el-popconfirm
                                    confirm-button-text='好的'
                                    cancel-button-text='不用了'
                                    icon="el-icon-delete"
                                    icon-color="red"
                                    :title="`删除菜单[${data.name}](不删除子菜单)？`"
                                    @confirm="removeMenu(node, data)"
                            >
                                 <el-button slot="reference"
                                         type="text"
                                         size="mini">
                                <i class="el-icon-delete"></i>
                              </el-button>
                            </el-popconfirm>
                        </template>

            </span>
          </span>
            </el-tree>
            <el-button class="save" type="primary" @click="saveOrderHandle">保 存</el-button>
        </el-main>
        <el-dialog :title="editForm.id?'编辑菜单':'新增菜单'" :visible.sync="editFormVisible">
            <el-form :model="editForm" :rules="rules" ref="editForm">
                <el-form-item label="名称" :label-width="formLabelWidth">
                    <el-input v-model="editForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="路由路径" :label-width="formLabelWidth">
                    <el-input v-model="editForm.routePath" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="路由组件" :label-width="formLabelWidth">
                    <el-input v-model="editForm.routeComponent" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="路由名称" :label-width="formLabelWidth">
                    <el-input v-model="editForm.routeName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否启用" prop="status">
                    <el-radio-group v-model="editForm.status">
                        <el-radio :label="1">禁用</el-radio>
                        <el-radio :label="0">正常</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitMenuHandle('editForm')">确 定</el-button>
            </div>
        </el-dialog>
    </el-container>
</template>

<script>
    import {menus2orders, orders2menus, checkorders, UNLINK_INDEX} from "../../global"
    const prefix = "/sys/menu";
    const getUrl = `${prefix}/system`;
    const putUrl = function (id) {
        return `${prefix}/system/update/${id}`;
    }
    const postUrl = `${prefix}/system/save`;
    const deleteUrl = function (id) {
        return `${prefix}/system/delete/${id}`;
    }
    const saveOrderUrl = `${prefix}/system/updateOrder`;
    export default {
        data(){
            return {
                allMenus:[],
                editForm: {},
                nodedata: {},
                editFormVisible: false,
                formLabelWidth: "80",
                rules:{
                    name: [
                        { required: true, message: '请输入菜单名', trigger: 'blur'},
                        { min: 3, max: 32, message: '长度在 3 到 32 个字符', trigger: 'blur' },
                        { pattern: /^\S.*\S$|(^\S{0,1}\S$)/,  message: '首尾不能为空格', trigger: 'blur' }
                    ],
                    status: [
                        { required: true, message: '请选择状态', trigger: 'blur'}
                    ]
                },
            }
        },
        created() {
            if(this.allMenus.length === 0) {
                let menumap = new Map();
                let menuorder = [];
                this.$axios.get(getUrl).then(res => {
                    let menulist = res.data.menuList;
                    menuorder = JSON.parse(res.data.orders);
                    menulist.forEach(menu => {
                        menumap.set(menu.id, {...menu, children:[]});
                    })
                    this.allMenus = orders2menus(menuorder, menumap, {removeMenu:true});
                    if(menumap.size > 0){
                        let unlink_tags = {
                            id: UNLINK_INDEX,
                            name: '未关联标签',
                            children: []
                        }
                        let unlink_pages = {
                            id: UNLINK_INDEX*2,
                            name: '未关联页面',
                            children: []
                        }
                        menumap.forEach(menu => {
                            if(menu.routeComponent){
                                unlink_pages.children.push(menu);
                            }else{
                                unlink_tags.children.push(menu);
                            }
                        })
                        this.allMenus.push(unlink_pages);
                        this.allMenus.push(unlink_tags);
                    }
                })
            }
        },
        methods:{
            appendMenu(data) {
                this.editForm = {};
                this.nodedata = data;
                this.editFormVisible = true;
            },
            editMenu(data){
                this.editForm = data;
                this.editFormVisible = true;
            },
            removeMenu(node, data) {
                const parent = node.parent;
                const children = parent.data.children || parent.data;
                const index = children.findIndex(d => d.id === data.id);
                children.splice(index, 1);
                this.$axios.delete(deleteUrl(data.id)).then(res=>{

                    // this.$message.success("删除成功");
                })
            },
            submitMenuHandle(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.editForm.id){
                            if(!this.editForm.children) this.editForm.children = [];
                            this.$axios.put(putUrl(this.editForm.id), this.editForm).then(res=>{
                                this.$message.success("修改成功");
                            })
                        }else{
                            const newChild = { ...this.editForm, children: []};
                            this.$axios.post(postUrl, newChild).then(res=>{
                                this.$message.success("新增成功");
                                console.log(res);
                                newChild.id = res.data.id
                                if (!this.nodedata.children) {
                                    this.$set(this.nodedata, 'children', []);
                                }
                                this.nodedata.children.push(newChild);
                                this.nodedata = {}
                            })

                        }
                        this.editFormVisible = false;
                    } else {
                        return false;
                    }
                })
            },
            saveOrderHandle(){
                let result = checkorders(this.allMenus);
                if(result.length > 0){
                    this.$message.error(`不符合(1)页面不能成为父节点(2)叶节点必须是页面,${result}[标签]位置错误`);
                    return ;
                }
                this.$axios.put(saveOrderUrl,{
                    menuTree: JSON.stringify(menus2orders(this.allMenus))
                }).then(res => {
                    this.$message.success("菜单顺序保存成功");
                })

                // let orders = {id:1 ,orders: menus2orders(this.allMenus)};
                // this.$axios.get(`/menuorder/${orders.id}`).then(res=>{
                //     if(res.data.length === 0){
                //         this.$axios.post('/menuorder', orders);
                //     }else{
                //         this.$axios.put(`/menuorder/${orders.id}`, orders);
                //     }
                //     this.$message.success("菜单顺序保存成功");
                // })
            },
            suffix(data){
                if(data.id >= UNLINK_INDEX) return '';
                if(data.routeComponent) return '[页面]';
                else{
                    return (data.type && data.type==="custom")?'[用户标签]':'[系统标签]';
                }
            },
        },

    }
</script>

<style scoped>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        /*justify-content: space-around;*/
        font-size: 14px;
        padding-right: 8px;
    }
    .custom-tree-node .el-button{
        margin: 0 5px;
    }
    .save{
        margin: 10px 0 0 130px;
    }


</style>